import { Router } from 'next/router';
import React, { FC, useEffect } from 'react';

import LandingSeason1 from 'components/Landing/LandingSeason1/LandingSeason1';
import LandingWelcomeSection, {
  LandingWelcomeSectionBodyVariant,
} from 'components/Landing/LandingWelcomeSection/LandingWelcomeSection';
import Layout from 'components/Layout/Layout';
import { buildAPIUrl, getApi } from 'services/api/apiUtils';
import { useGlobalStore } from 'store';
import { getCopy } from 'store/copy.data';
import { CopyStoreType } from 'store/copy.types';
import GTM from 'u9/utils/gtm';
import { Pages } from 'utils/routes';

import { API_T } from 'constants/enum';

interface LandingPageProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
  };
  data: any;
  router: Router;
}

const LandingPage: FC<LandingPageProps> = ({ data }) => {
  const { season, setIsNftLimitReached } = useGlobalStore();

  useEffect(() => {
    if (data?.isRegistrationOpen === false) setIsNftLimitReached(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    GTM.trackPage('/', 'Homepage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.DefaultWrapper $noPaddingBottom>
      <Layout.FluidContainer>
        <LandingWelcomeSection
          variant={LandingWelcomeSectionBodyVariant.S1_END}
        />
      </Layout.FluidContainer>
      {season === 1 && <LandingSeason1 />}
    </Layout.DefaultWrapper>
  );
};

export default LandingPage;

export const getServerSideProps: any = async ({ locale }) => {
  const { head } = getCopy(Pages.index, locale);

  const response = await getApi(
    buildAPIUrl({ type: API_T.exists, value: 'sj@sj.sj' })
  );

  return {
    props: {
      initialCopy: {
        head,
      },
      data: response.data,
    },
  };
};
