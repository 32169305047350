import { FC, useCallback } from 'react';

import { ROUTES } from 'utils/routes';

import * as Styled from './LandingLab.styles';

export interface LandingLabProps {
  isLoggedIn: boolean;
  onClickLab: (
    elementToZoom: {
      width: number;
      height: number;
      top: number;
      left: number;
    },
    nextRoute: string
  ) => void;
}

const LandingLab: FC<LandingLabProps> = ({ onClickLab, isLoggedIn }) => {
  const handleOnClickLab = useCallback(
    (e, nextRoute) =>
      onClickLab(
        {
          width: e.currentTarget.offsetWidth,
          height: e.currentTarget.offsetHeight,
          top: e.currentTarget.offsetTop,
          left: e.currentTarget.offsetLeft,
        },
        nextRoute
      ),
    [onClickLab]
  );

  return (
    <Styled.Wrapper>
      {isLoggedIn && (
        <>
          <Styled.Lab1
            onClick={e => handleOnClickLab(e, ROUTES.LAB_MEATERIALIZER)}
          />
          <Styled.Lab2
            onClick={e => handleOnClickLab(e, ROUTES.LAB_SAUCE_MIXER)}
          />
          <Styled.Lab3
            onClick={e => handleOnClickLab(e, ROUTES.LAB_INVENTORY)}
          />
        </>
      )}
    </Styled.Wrapper>
  );
};

export default LandingLab;
