import { useEffect, useState } from 'react';

import * as Styled from './SkyWithStars.styles';

const limit = 25;

export type StarProps = {
  top: number;
  left: number;
  size: number;
  delay: number;
  blur: number;
  inverted: boolean;
};

const SkyWithStars: React.FC = () => {
  const [stars, setStars] = useState<StarProps[]>([]);

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < limit; i++) {
      const newStar = {
        top: Math.random() * 100,
        left: Math.random() * 100,
        size: Math.floor(Math.random() * 2.5) + 1,
        delay: Math.random() * 10,
        inverted: Math.random() < 0.5 ? false : true,
      };
      stars.push(newStar);
    }
    setStars(stars);
  };

  useEffect(() => renderStars(), []);

  return (
    <Styled.Sky>
      {stars.map((star, index) => (
        <Styled.Star key={index} {...star} />
      ))}
    </Styled.Sky>
  );
};

export default SkyWithStars;
