import styled, { keyframes } from 'styled-components';

import { StarProps } from './SkyWithStars';

const blink = (inverted: boolean) => keyframes`
  0% {
    opacity: ${inverted ? 0 : 1};
  }
  50% {
    opacity: ${inverted ? 1 : 0};
  }
  100% {
       opacity: ${inverted ? 0 : 1};
  }
`;

export const Star = styled.div<StarProps>`
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  filter: blur(${({ blur }) => blur}px});
  border-radius: 50%;
  background-color: white;
  opacity: ${({ inverted }) => (inverted ? 0 : 1)};
  animation: ${({ inverted }) => blink(inverted)} ${Math.random() * 4 + 1}s
    ease-in-out infinite;
  animation-delay: ${({ delay }) => delay}s;
`;

export const Sky = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
