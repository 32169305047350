import { FC } from 'react';

import SkyWithComet from 'components/SkyWithComet/SkyWithComet';
import SkyWithStars from 'components/SkyWithStars/SkyWithStars';

import * as Styled from './LandingSky.styles';

const LandingSky: FC = () => {
  return (
    <>
      <Styled.SkyTop>
        <SkyWithComet />
      </Styled.SkyTop>
      <Styled.SkyLeft>
        <SkyWithStars />
      </Styled.SkyLeft>
      <Styled.SkyMiddle>
        <SkyWithStars />
      </Styled.SkyMiddle>
      <Styled.SkyRight>
        <SkyWithStars />
      </Styled.SkyRight>
    </>
  );
};

export default LandingSky;
