import styled, { css } from 'styled-components';

import { aspectRatio } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  /* ${aspectRatio(1170, 4317)}; */
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;

  ${mediaTablet(css`
    height: 1850rem;
    ${aspectRatio(5870, 1839)};
  `)}
`;

export const Window = styled.div`
  width: 12.5%;
  height: 16.2%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 38.3%;
    height: 45.1%;
    opacity: 0;
    transition: opacity 0.6s;
  }
`;

export const WindowGroup = styled.div`
  position: absolute;
  left: 18.7%;
  width: 10.2%;
  height: 22.4%;
  top: 59.3%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
`;
