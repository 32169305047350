import { FC, ReactNode } from 'react';

import * as Styled from './CircuitContent.styles';

export const validBodySizes = ['sm', 'lg'] as const;

export interface CircuitContentProps {
  header?: string;
  title?: string;
  titleDecoration?: boolean;
  body?: string;
  bodySize?: typeof validBodySizes[number];
  bodyUpperCase?: boolean;
  footer?: string;
  extraContent?: ReactNode;
}

const CircuitContent: FC<CircuitContentProps> = ({
  header,
  title,
  titleDecoration,
  body,
  bodySize,
  bodyUpperCase = true,
  footer,
  extraContent,
}) => {
  return (
    <Styled.Wrapper>
      {header && (
        <Styled.Header>
          <span>{header}</span>
        </Styled.Header>
      )}
      {title && (
        <Styled.Title as="h2" $titleDecoration={titleDecoration}>
          {title}
        </Styled.Title>
      )}
      {body && (
        <Styled.Body
          $bodySize={bodySize}
          $bodyUpperCase={bodyUpperCase}
          dangerouslySetInnerHTML={{ __html: body }}
        ></Styled.Body>
      )}
      {footer && <Styled.Footer>{footer}</Styled.Footer>}
      {extraContent && (
        <Styled.ExtraContent>{extraContent}</Styled.ExtraContent>
      )}
    </Styled.Wrapper>
  );
};

export default CircuitContent;
