import styled, { css } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  margin-top: 170rem;

  ${mediaTablet(css`
    margin-top: 300rem;
  `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CapsulesWrapper = styled.div`
  margin: 48rem 0 100rem;
  width: 100%;
  height: 100%;
  padding: 0 6rem;

  ${mediaTablet(css`
    margin: 160rem 0 220rem;
    width: auto;
  `)}
`;

export const CapsulesContentWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 116rem;
  position: relative;
`;

export const CapsulePreview = styled.div`
  height: 680rem;
  width: 350rem;

  ${mediaTablet(css`
    height: 912rem;
    width: 469rem;
  `)}
`;

export const CapsulesWires = styled.div`
  width: 2005rem;
  position: absolute;
  left: -103rem;
  bottom: -336rem;
  pointer-events: none;
`;

export const CapsulesWiresImg = styled.img`
  width: 100%;
  height: 100%;
  user-select: none;
`;

export const CapsulesWiresAnimation = styled.div`
  position: absolute;
  width: 480rem;
  bottom: 35rem;
  right: -165rem;
`;
