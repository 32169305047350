import { FC, useCallback, useRef, useState } from 'react';
import { Swiper } from 'swiper/react';

import * as Styled from './Slider.styles';

import 'swiper/css';

export interface SliderProps {
  currentIndex?: number;
  setCurrentIndex?: (index: number) => void;
  hasPagination?: boolean;
}

const Slider: FC<SliderProps> = ({
  currentIndex,
  setCurrentIndex,
  children,
  hasPagination = false,
}) => {
  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const slideTo = useCallback((index: number) => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(index);
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesLength, setSlidesLength] = useState(0);

  const index = currentIndex || activeIndex;

  return (
    <Styled.Wrapper>
      <Swiper
        slidesPerView={1}
        ref={sliderRef}
        onSlideChange={swiper => {
          if (setCurrentIndex) setCurrentIndex(swiper.activeIndex);
          else setActiveIndex(swiper.activeIndex);
        }}
        onInit={swiper => {
          setSlidesLength(swiper.slides.length);
        }}
        onSlidesLengthChange={swiper => {
          setSlidesLength(swiper.slides.length);
        }}
      >
        {children}
      </Swiper>
      <Styled.Prev
        icon="arrow-left"
        onClick={handlePrev}
        $visible={index > 0}
      />
      <Styled.Next
        icon="arrow-right"
        onClick={handleNext}
        $visible={index < slidesLength - 1}
      />
      {hasPagination && (
        <Styled.Dots $index={index}>
          {Array(slidesLength)
            .fill('')
            .map((slide, i) => (
              <Styled.Dot key={i} onClick={() => slideTo(i)} />
            ))}
        </Styled.Dots>
      )}
    </Styled.Wrapper>
  );
};

export default Slider;
