import styled, { css } from 'styled-components';

import Button from 'components/Button/Button';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  .swiper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .swiper-slide {
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NavButton = styled(Button)<{
  $visible?: boolean;
}>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 1;
  transform: scale(0.9);

  ${({ $visible }) =>
    $visible &&
    css`
      display: block;
    `};
`;

export const Prev = styled(NavButton)`
  left: 36rem;
`;

export const Next = styled(NavButton)`
  right: 36rem;
`;

export const Dots = styled.div<{
  $index: number;
}>`
  justify-content: center;
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  margin-top: 24rem;
  margin-bottom: 36rem;

  > div:nth-child(${({ $index }) => $index + 1}) {
    background-color: ${({ theme }) => theme.colors.turbo};
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 14px;
  background-color: ${({ theme }) => theme.colors.rumSwizzle};
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.black};
`;
