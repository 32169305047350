import styled from 'styled-components';

import assets from 'utils/assets';
import { backgroundImage } from 'utils/styles/mixins';

type CometProps = {
  top: number;
  left: number;
};

export const Comet = styled.div<CometProps>`
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  ${backgroundImage(assets.images.landingPage.comet, [400, 200])}
  width: 16em;
  height: 8em;
`;

export const Sky = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
