export const TOTAL_LIGHTS_ON = 0.3; // 0-1: Percentage of total windows that must be ON at the same time
export const ON_DURATION = 7500;
export const GROUPS_SETTINGS = [
  {
    columns: 2,
    rows: 6,
    width: 2.4,
    height: 22.4,
    top: 64.1,
    left: 2,
  },
  {
    columns: 4,
    rows: 6,
    width: 5.1,
    height: 21.7,
    top: 34.4,
    left: 8.1,
  },
  {
    columns: 2,
    rows: 6,
    width: 2.4,
    height: 22.4,
    top: 64.1,
    left: 10.6,
  },
  {
    columns: 8,
    rows: 6,
    width: 10.2,
    height: 22.4,
    top: 59.2,
    left: 18.75,
  },
  {
    columns: 4,
    rows: 1,
    width: 4.8,
    height: 3.3,
    top: 49.1,
    left: 61.75,
  },
  {
    columns: 2,
    rows: 2,
    width: 2.4,
    height: 6.6,
    top: 52.4,
    left: 64.15,
  },
  {
    columns: 4,
    rows: 6,
    width: 5.1,
    height: 21.7,
    top: 56.6,
    left: 73.9,
  },
  {
    columns: 4,
    rows: 5,
    width: 4.9,
    height: 18.5,
    top: 37.6,
    left: 76.6,
  },
  {
    columns: 2,
    rows: 6,
    width: 2.4,
    height: 22.4,
    top: 56.2,
    left: 79.1,
  },
  {
    columns: 4,
    rows: 6,
    width: 5.1,
    height: 21.7,
    top: 64.4,
    left: 87.1,
  },
];
