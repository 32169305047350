import { useRouter } from 'next/router';
import { FC } from 'react';
import { SwiperSlide } from 'swiper/react';

import ApngAnimation from 'components/ApngAnimation/ApngAnimation';
import Button from 'components/Button/Button';
import Capsule from 'components/Capsule/Capsule';
import CircuitContent from 'components/Circuit/CircuitContent/CircuitContent';
import NewCircuit from 'components/NewCircuit/NewCircuit';
import Slider from 'components/Slider/Slider';
import { useCopyStore, useGlobalStore } from 'store';
import { GTM_CATEGORY } from 'u9/utils/gtm';
import GTM from 'u9/utils/gtm';
import assets, { NewCircuitVariant } from 'utils/assets';
import { ROUTES } from 'utils/routes';

import * as Styled from './LandingCapsulesCircuit.styles';

export interface LandingCapsulesCircuitProps {}

const LandingCapsulesCircuit: FC<LandingCapsulesCircuitProps> = () => {
  const copy = useCopyStore(({ copy }) => copy.index.content.season1.circuit1);
  const capsules = Object.values(assets.svgs.landingPage.capsules);
  const { isDesktopView } = useGlobalStore();
  const router = useRouter();

  return (
    <Styled.Wrapper>
      <NewCircuit variant={NewCircuitVariant.CAPSULES} showAnimations={false}>
        <Styled.Content>
          <CircuitContent title={copy.title} body={copy.body} />
          <Styled.CapsulesWrapper>
            {isDesktopView ? (
              <NewCircuit variant={NewCircuitVariant.CAPSULES_BG}>
                <Styled.CapsulesContentWrapper>
                  {capsules.map((capsuleSrc, index) => (
                    <Styled.CapsulePreview key={index}>
                      <Capsule />
                    </Styled.CapsulePreview>
                  ))}
                  <Styled.CapsulesWires>
                    <Styled.CapsulesWiresImg
                      src={assets.svgs.landingPage.capsulesWires}
                    />
                    <Styled.CapsulesWiresAnimation>
                      <ApngAnimation src={assets.images.animations.sparks03} />
                    </Styled.CapsulesWiresAnimation>
                  </Styled.CapsulesWires>
                </Styled.CapsulesContentWrapper>
              </NewCircuit>
            ) : (
              <Slider>
                {capsules.map((capsule, index) => (
                  <SwiperSlide key={capsule}>
                    <Styled.CapsulePreview key={index}>
                      <Capsule />
                    </Styled.CapsulePreview>
                  </SwiperSlide>
                ))}
              </Slider>
            )}
          </Styled.CapsulesWrapper>
          <Button
            label={copy.cta}
            paddingSize="sm"
            onClick={() => {
              GTM.trackEvent(
                GTM_CATEGORY.Homepage,
                'Click',
                'Explore The Collection'
              );
              router.push(ROUTES.GALLERY);
            }}
          />
        </Styled.Content>
      </NewCircuit>
    </Styled.Wrapper>
  );
};

export default LandingCapsulesCircuit;
