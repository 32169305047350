import { FC } from 'react';

import * as Styled from './FlashingLight.styles';

export interface FlashingLightProps {
  colors?: string[];
}

const defaultColors = ['#C8102E', '#107EFE', '#3F536B', '#FFDA00', '#21366B'];

const FlashingLight: FC<FlashingLightProps> = ({ colors = defaultColors }) => (
  <Styled.Container colors={colors} />
);

export default FlashingLight;
