import { FC, useEffect, useRef } from 'react';

import BuildingLight from 'assets/images/building_lights.png';

import { GROUPS_SETTINGS, ON_DURATION, TOTAL_LIGHTS_ON } from './settings';

import * as Styled from './LandingBuildingLights.styles';

const LandingBuildingLights: FC = () => {
  const oldWindowsRef = useRef<HTMLImageElement[]>([]);
  const animationIntervalRef = useRef<ReturnType<typeof setInterval>>(null);
  const wrapperRef = useRef<HTMLDivElement>();

  const createItems = (total: number, width: number, height: number) => {
    const arrFromTotal = Array.from({ length: total }, (_, index) => index);
    return arrFromTotal.map(i => (
      <Styled.Window
        key={i}
        style={{
          width: `${width}%`,
          height: `${height}%`,
        }}
      >
        <img src={BuildingLight} />
      </Styled.Window>
    ));
  };
  const createGroups = () => {
    return GROUPS_SETTINGS.map((group, index) => (
      <Styled.WindowGroup
        key={`group_${index}`}
        style={{
          width: `${group.width}%`,
          height: `${group.height}%`,
          top: `${group.top}%`,
          left: `${group.left}%`,
        }}
      >
        {createItems(
          group.columns * group.rows,
          100 / group.columns,
          100 / group.rows
        )}
      </Styled.WindowGroup>
    ));
  };

  const randomSortPercent = arr => {
    const length = arr.length;
    const thirtyPercent = Math.ceil(length * TOTAL_LIGHTS_ON);

    const indices = Array.from({ length: length }, (_, i) => i);

    for (let i = length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [indices[i], indices[j]] = [indices[j], indices[i]];
    }

    const slicedIndices = indices.slice(0, thirtyPercent);

    const sortedElements = slicedIndices
      .map(index => arr[index])
      .sort(() => Math.random() - 0.5);

    return sortedElements;
  };

  const turnLights = () => {
    const windowsArr = wrapperRef.current.querySelectorAll('img');
    if (windowsArr) {
      oldWindowsRef.current.forEach(item => (item.style.opacity = '0'));
      oldWindowsRef.current = [];
      oldWindowsRef.current = randomSortPercent(Array.from(windowsArr));
      oldWindowsRef.current.forEach(item => {
        item.style.opacity = '1';
        item.style.transitionDelay = `${Math.random() * 5}s`;
      });
    }
  };

  useEffect(() => {
    clearInterval(animationIntervalRef.current);
    animationIntervalRef.current = setInterval(() => {
      turnLights();
    }, ON_DURATION);
    turnLights();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {
      clearInterval(animationIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Styled.Wrapper ref={wrapperRef}>{createGroups()}</Styled.Wrapper>;
};

export default LandingBuildingLights;
