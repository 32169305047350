import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styled, { css } from 'styled-components';

import { setTextStyles, setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

import { CircuitContentProps } from './CircuitContent';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;

  ${mediaTablet(css`
    padding: 0 50rem;
  `)}
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.colors.turbo};
  color: ${({ theme }) => theme.colors.black};
  padding: 20rem 45rem 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6rem solid ${({ theme }) => theme.colors.black};
  border-radius: 400rem;
  margin-bottom: 32rem;

  span {
    ${({ theme: { locale } }) => setTypography('heading2', locale)};
    text-transform: uppercase;
    text-align: center;
  }

  ${mediaTablet(css`
    margin-bottom: 40rem;
  `)}
`;

export const Title = styled(ReactMarkdown)<{
  $titleDecoration: CircuitContentProps['titleDecoration'];
}>`
  ${({ theme: { locale } }) => setTypography('heading1', locale)};
  ${setTextStyles('turbo', 'xl', 0.8)};
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
  padding: 0 28rem;

  ${({ $titleDecoration }) =>
    $titleDecoration &&
    css`
      background: ${({ theme }) => theme.colors.fiord};
      border: 6rem solid ${({ theme }) => theme.colors.black};
      border-radius: 100rem;
      padding: 46rem 0;
      max-width: 600rem;

      ${mediaTablet(css`
        max-width: none;
        margin: 0 -50rem 36rem;
        border-radius: 400rem;
        padding: 56rem 70rem;
      `)}
    `};
`;

export const Body = styled.p<{
  $bodySize: CircuitContentProps['bodySize'];
  $bodyUpperCase: boolean;
}>`
  ${({ theme: { locale }, $bodyUpperCase }) =>
    setTypography($bodyUpperCase ? 'heading5' : 'heading5Normal', locale)};
  ${setTextStyles('rumSwizzle', 'md')};
  text-align: center;
  margin: 50rem 30rem 0;

  ${mediaTablet(css`
    margin: 62rem 20rem 0;
    white-space: pre-wrap;
  `)}

  ${({ $bodySize }) =>
    $bodySize === 'lg' &&
    css`
      ${({ theme: { locale } }) => setTypography('heading2', locale)};
      margin-top: 40rem;

      ${mediaTablet(css`
        margin-top: 52rem;
      `)}
    `};
`;

export const Footer = styled.p`
  ${({ theme: { locale } }) => setTypography('body1', locale)};
  ${setTextStyles('rumSwizzle', 'md')};
  text-transform: uppercase;
  text-align: center;
  margin: 44rem 50rem 0;

  ${mediaTablet(css`
    margin: 50rem 0 0;
  `)}
`;

export const ExtraContent = styled.div`
  margin-top: 44rem;

  a {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  ${mediaTablet(css`
    margin: 50rem;
  `)}
`;
