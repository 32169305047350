import styled, { css, keyframes } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

export function randomFlashingColorsKeyFrames(colors: string[]) {
  const randomKeyframes = colors
    .map(
      color => `
      ${Math.floor(Math.random() * 101)}% {
        background-color: ${color};
      }
    `
    )
    .join('');

  return keyframes`${randomKeyframes}`;
}

export const Container = styled.div<{ colors: string[] }>`
  height: 1.2em;
  width: 0.5em;
  min-width: 10px;
  border: 6rem solid black;
  border-top-right-radius: 6rem;
  border-bottom-right-radius: 6rem;
  border-left: none;
  animation: ${({ colors }) => randomFlashingColorsKeyFrames(colors)} 10s
    infinite step-start;

  ${mediaTablet(css`
    height: 1.7em;
    width: 0.7em;
    min-width: 22rem;
    border: 8rem solid black;
    border-top-right-radius: 12rem;
    border-bottom-right-radius: 12rem;
  `)}
`;
