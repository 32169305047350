import React, { FC } from 'react';
import SVG from 'react-inlinesvg';

import { errorMe } from 'utils/logger';

export interface SvgFCProps {
  src?: any;
}

export const SvgFC: FC<SvgFCProps> = ({ src }) => {
  return <>{typeof src === 'string' ? <SVG src={src} /> : errorMe(src)}</>;
};

export default SVG;
