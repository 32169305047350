import { FC } from 'react';

import circuit2WindowBg from 'assets/images/circuit2-bg.png';
import circuit2WindowBgMobile from 'assets/images/circuit2-bg-mobile.png';
import Button from 'components/Button/Button';
import { useGlobalStore } from 'store';
import assets from 'utils/assets';

import CircuitContent from './CircuitContent/CircuitContent';
import { SvgFC } from './SvgImage';

import * as Styled from './Circuit.styles';

export enum CircuitVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  WIRES = 'wires',
  ACCOUNT = 'account',
  ERROR = 'error',
  ABOUT_HISTORY = 'aboutHistory',
  FOOTER = 'footer',
  STATISTICS = 'statistics',
}

export interface CircuitProps {
  variant: CircuitVariant;
  hasDecorationPipes?: boolean;
  hasPadding?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
  as?: 'section' | 'footer';
}

const circuitVariants: Record<
  CircuitVariant,
  {
    Circuit: any;
    CircuitMobile: any;
    DecorationPipes?: any;
    DecorationPipesMobile?: any;
  }
> = {
  [CircuitVariant.PRIMARY]: {
    Circuit: assets.svgs.circuit.circuit1,
    CircuitMobile: assets.svgs.circuit.circuit1Mobile,
    DecorationPipes: assets.svgs.circuit.circuit1Pipes,
    DecorationPipesMobile: assets.svgs.circuit.circuit1PipesMobile,
  },
  [CircuitVariant.SECONDARY]: {
    Circuit: assets.svgs.circuit.circuit2,
    CircuitMobile: assets.svgs.circuit.circuit2Mobile,
    DecorationPipes: assets.svgs.circuit.circuit2Pipes,
    DecorationPipesMobile: assets.svgs.circuit.circuit2PipesMobile,
  },
  [CircuitVariant.WARNING]: {
    Circuit: assets.svgs.circuit.circuit4,
    CircuitMobile: assets.svgs.circuit.circuit4Mobile,
  },
  [CircuitVariant.WIRES]: {
    Circuit: assets.svgs.circuit.circuit5,
    CircuitMobile: assets.svgs.circuit.circuit5Mobile,
  },
  [CircuitVariant.ACCOUNT]: {
    Circuit: assets.svgs.circuit.circuit12,
    CircuitMobile: assets.svgs.circuit.circuit12Mobile,
  },
  [CircuitVariant.STATISTICS]: {
    Circuit: assets.svgs.circuit.circuit13,
    CircuitMobile: assets.svgs.circuit.circuit13Mobile,
  },
  [CircuitVariant.ERROR]: {
    Circuit: assets.svgs.circuit.circuit6,
    CircuitMobile: assets.svgs.circuit.circuit6Mobile,
  },
  [CircuitVariant.ABOUT_HISTORY]: {
    Circuit: assets.svgs.circuit.circuit8,
    DecorationPipes: assets.svgs.circuit.circuit8Background,
    CircuitMobile: assets.svgs.circuit.circuit8Mobile,
    DecorationPipesMobile: assets.svgs.circuit.circuit8BackgroundMobile,
  },
  [CircuitVariant.FOOTER]: {
    Circuit: assets.svgs.circuit.circuit3,
    CircuitMobile: assets.svgs.circuit.circuit3Mobile,
  },
};

export const circuitVariantsWithCloseButton = [
  CircuitVariant.WARNING,
  CircuitVariant.WIRES,
  CircuitVariant.ERROR,
];

interface Circuit extends FC<CircuitProps> {
  Content: typeof CircuitContent;
}
/**
 * @deprecated Better use NewCircuit instead
 */
const Circuit: Circuit = ({
  variant,
  hasDecorationPipes,
  hasPadding = true,
  children,
  hasCloseButton,
  onClose,
  as,
}) => {
  const { isDesktopView } = useGlobalStore();
  const { Circuit, CircuitMobile, DecorationPipes, DecorationPipesMobile } =
    circuitVariants[variant];

  return (
    <Styled.Wrapper
      $variant={variant}
      $hasDecorationPipes={!!DecorationPipes}
      $hasPadding={hasPadding}
      as={as}
    >
      {hasDecorationPipes && DecorationPipes && (
        <Styled.CircuitPipes $variant={variant}>
          {isDesktopView ? (
            <SvgFC src={DecorationPipes} />
          ) : (
            DecorationPipesMobile && <SvgFC src={DecorationPipesMobile} />
          )}
        </Styled.CircuitPipes>
      )}
      <Styled.Circuit $variant={variant}>
        <SvgFC src={isDesktopView ? Circuit : CircuitMobile} />

        {variant === CircuitVariant.SECONDARY && (
          <Styled.Circuit2WindowBg
            src={isDesktopView ? circuit2WindowBg : circuit2WindowBgMobile}
          />
        )}
        {circuitVariantsWithCloseButton.includes(variant) &&
          hasCloseButton &&
          onClose && (
            <Styled.CloseButton $variant={variant}>
              <Button icon="close2" onClick={onClose} />
            </Styled.CloseButton>
          )}
        {children && (
          <Styled.Content $variant={variant}>{children}</Styled.Content>
        )}
      </Styled.Circuit>
    </Styled.Wrapper>
  );
};

Circuit.Content = CircuitContent;

export default Circuit;
