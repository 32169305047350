import styled, { css } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

export const SkyLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 33vw;
  height: 850rem;

  ${mediaTablet(
    css`
      height: 33%;
    `
  )}
`;

export const SkyMiddle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 33vw;
  height: 850rem;

  ${mediaTablet(
    css`
      height: 45%;
    `
  )}
`;

export const SkyRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 33vw;
  height: 850rem;

  ${mediaTablet(
    css`
      height: 33%;
    `
  )}
`;

export const SkyTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 850rem;

  ${mediaTablet(
    css`
      height: 25%;
    `
  )}
`;
