import { useEffect, useRef, useState } from 'react';

import * as Styled from './SkyWithComet.styles';

const SkyWithComet: React.FC = () => {
  const [comet, setComet] = useState<{ top: number; left: number }>(null);
  const timeoutRef = useRef(null);

  const generateComet = () => {
    const newComet = {
      top: Math.random() * 100,
      left: Math.random() * 100,
    };
    setComet(newComet);
    timeoutRef.current = setTimeout(() => setComet(null), 2250);
  };

  useEffect(() => {
    const interval = setInterval(() => generateComet(), 4500);
    return () => {
      clearInterval(interval);
      timeoutRef?.current && clearTimeout(timeoutRef.current);
    };
  }, [comet]);

  return <Styled.Sky>{comet && <Styled.Comet {...comet} />}</Styled.Sky>;
};

export default SkyWithComet;
