import styled, { css } from 'styled-components';

import { Body } from 'components/Circuit/CircuitContent/CircuitContent.styles';
import { BodyPart } from 'components/NewCircuit/NewCircuit.styles';
import assets from 'utils/assets';
import { aspectRatio, backgroundImage } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  ${backgroundImage(
    assets.images.landingPage.elevatorsBackground.mobile,
    [2924, 7088]
  )}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100%;

  ${mediaTablet(css`
    ${backgroundImage(
      assets.images.landingPage.elevatorsBackground.desktop,
      [2924, 7060]
    )}
  `)}
`;

export const LinksList = styled.ul`
  display: flex;
  background: ${({ theme }) => theme.colors.fiord};
  border: 6rem solid ${({ theme }) => theme.colors.black};
  border-radius: 400rem;
  padding: 26rem 32rem;
  transform: scale(0.92);

  li {
    display: block;
  }

  li:not(:last-child) {
    margin-right: 20rem;
  }

  ${mediaTablet(css`
    padding: 32rem;
    transform: none;

    li:not(:last-child) {
      margin-right: 40rem;
    }
  `)}
`;

export const PrimaryWrapper = styled.div`
  margin-top: 36rem;
  margin-bottom: 40rem;

  h2 {
    white-space: normal;
  }

  ${mediaTablet(css`
    margin-top: 270rem;
    margin-bottom: 280rem;

    h2 {
      white-space: pre-wrap;
    }
  `)}
`;

export const SecondaryWrapper = styled.div`
  margin-top: 36rem;
  margin-bottom: 40rem;

  ${BodyPart} {
    overflow: hidden;
  }

  ${Body} {
    font-style: normal;
  }

  ${mediaTablet(css`
    margin-top: 270rem;
  `)}
`;

export const SecondaryContentWrapper = styled.div`
  display: flex;
  height: 934rem;
  width: 662rem;
  margin: 22rem auto 0;
  background: url(${assets.images.landingPage.circuitBackground.mobile});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  ${mediaTablet(css`
    height: 1150rem;
    width: 1780rem;
    margin: 65rem auto 0;
    background-image: url(${assets.images.landingPage.circuitBackground
      .desktop});
  `)}
`;

export const FooterWrapper = styled.div`
  margin-bottom: 290rem;

  a {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  h2 {
    white-space: normal;
  }

  ${mediaTablet(css`
    margin-bottom: 390rem;

    h2 {
      white-space: pre-wrap;
    }
  `)}
`;

export const ElevatorsGround = styled.img`
  width: 100%;
  height: 190rem;
  pointer-events: none;
  user-select: none;
`;

export const LandingLabWrapper = styled.div`
  ${aspectRatio(1170, 4317)};
  width: 100%;

  ${mediaTablet(css`
    ${aspectRatio(3024, 1500)};
  `)}
`;

export const FlashingLightsPrimary = styled.div`
  position: absolute;
  top: 21%;
  right: -1.9em;
  bottom: 0;
  height: 48.5%;
  width: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaTablet(css`
    top: 15%;
    right: -3.2em;
    height: 68.8%;
    width: 2em;
  `)}
`;

export const FlashingLightsSecondary = styled.div`
  ${mediaTablet(css`
    position: absolute;
    transform: rotate(180deg);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 25%;
    left: -1.7em;
    height: 4.5em;
    gap: 0.3em;
    width: 1em;
  `)}
`;
