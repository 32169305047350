import gsap from 'gsap';
import { MutableRefObject, useCallback, useRef } from 'react';

interface MotionRef {
  zoom: (
    elementToZoom: {
      width: number;
      height: number;
      top: number;
      left: number;
    },
    callback: () => void
  ) => gsap.core.Timeline;
  landingContentRef: MutableRefObject<HTMLDivElement>;
  landingLabRef: MutableRefObject<HTMLDivElement>;
}

const useMotion = () => {
  const timelineRef = useRef<gsap.core.Timeline>(null);
  const isReady = useRef<boolean>(false);
  const landingContent = useRef<HTMLDivElement>(null);
  const landingLab = useRef<HTMLDivElement>(null);

  const zoom = useCallback(
    (
      elementToZoom: {
        width: number;
        height: number;
        top: number;
        left: number;
      },
      callback: () => void
    ) => {
      if (timelineRef.current) {
        timelineRef.current.kill();
      }

      timelineRef.current = gsap.timeline({
        onStart: () => {
          isReady.current = true;
        },
        onComplete: () => {
          callback();
          timelineRef.current = null;
        },
      });

      const viewport = {
        height: window.innerHeight,
        width: window.innerWidth,
      };
      const landingLayoutElement = landingContent.current.parentElement;
      const labOffset = {
        top:
          landingLayoutElement.offsetHeight - landingLab.current.offsetHeight,
        left:
          (landingLayoutElement.offsetWidth - landingLab.current.offsetWidth) /
          2,
      };
      const scrollY = window.scrollY
        ? window.scrollY
        : -parseInt(landingLayoutElement.style.top);

      if (
        !landingLayoutElement.style.top ||
        landingLayoutElement.style.top === '0px'
      ) {
        timelineRef.current.set(landingLayoutElement, {
          top: `-${window.scrollY}px`,
        });
      } // keep scroll position

      timelineRef.current.set(landingLayoutElement, {
        transformOrigin: `${
          labOffset.left + elementToZoom.left + elementToZoom.width / 2
        }px ${labOffset.top + elementToZoom.top + elementToZoom.height / 2}px`,
        position: 'fixed',
      });

      timelineRef.current.to(
        landingLayoutElement,
        {
          translateX:
            -(elementToZoom.left - landingLab.current.offsetWidth / 2) -
            elementToZoom.width / 2,
          translateY:
            -(
              labOffset.top -
              scrollY +
              elementToZoom.top -
              viewport.height / 2
            ) -
            elementToZoom.height / 2,
          scale: 3.85,
          // filter: 'blur(3px)',
          ease: 'power1.inOut',
        },
        0.1
      );

      return timelineRef.current;
    },
    []
  );

  return {
    zoom,
    landingContentRef: landingContent,
    landingLabRef: landingLab,
  } as MotionRef;
};

export default useMotion;
