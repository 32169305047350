import styled, { css } from 'styled-components';

import assets from 'utils/assets';
import { backgroundImage } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  ${backgroundImage(
    assets.images.landingPage.lab.mobile,
    [1170, 4314],
    assets.images.landingPage.lab.desktop,
    [3024, 1500]
  )};
  width: 100%;
  position: relative;
`;

export const ZoomButton = styled.button`
  cursor: pointer;
  position: absolute;
  width: 100vw;
  height: 460rem;

  ${mediaTablet(css`
    width: 980rem;
    height: 420rem;
  `)}
`;

export const Lab1 = styled(ZoomButton)`
  top: 780rem;
  left: 0;

  ${mediaTablet(css`
    top: 122rem;
    left: 1650rem;
  `)}
`;

export const Lab2 = styled(ZoomButton)`
  top: 1280rem;
  left: 0;

  ${mediaTablet(css`
    top: 520rem;
    left: 380rem;
    width: 1020rem;
  `)}
`;

export const Lab3 = styled(ZoomButton)`
  top: 1780rem;
  left: 0;

  ${mediaTablet(css`
    top: 930rem;
    left: 1650rem;
    width: 1020rem;
  `)}
`;
