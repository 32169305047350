import styled, { css } from 'styled-components';

import { mediaDesktopWide, mediaTablet } from 'utils/styles/responsive';
import { layoutMaxWidthDesktop } from 'utils/styles/responsive';

import { CircuitProps, CircuitVariant } from './Circuit';

export const Wrapper = styled.div<{
  $variant: CircuitProps['variant'];
  $hasDecorationPipes: CircuitProps['hasDecorationPipes'];
  $hasPadding: CircuitProps['hasPadding'];
}>`
  width: 100vw;
  position: relative;

  ${mediaDesktopWide(css`
    width: min(100vw, ${layoutMaxWidthDesktop}rem);
  `)}

  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding: 100rem 0 0;

      ${mediaTablet(css`
        padding: 300rem 0;
      `)}
    `};

  ${({ $variant, $hasDecorationPipes, $hasPadding }) =>
    $variant === CircuitVariant.PRIMARY &&
    $hasDecorationPipes &&
    $hasPadding &&
    css`
      padding: 200rem 0 0;

      ${mediaTablet(css`
        padding: 470rem 0 200rem;
      `)}
    `};

  ${({ $variant, $hasPadding }) =>
    $variant === CircuitVariant.FOOTER &&
    $hasPadding &&
    css`
      padding: 140rem 0 0;
      margin-bottom: -4rem;

      ${mediaTablet(css`
        padding: 450rem 0 0;
        margin-bottom: -20rem;
      `)};
    `};
`;

export const Circuit = styled.div<{ $variant: CircuitProps['variant'] }>`
  position: relative;
  width: 100%;

  ${mediaTablet(css<{ $variant: CircuitProps['variant'] }>`
    ${({ $variant }) =>
      $variant === CircuitVariant.PRIMARY &&
      css`
        width: 2540rem;
      `};
  `)};
`;

export const CircuitPipes = styled.div<{
  $variant: CircuitProps['variant'];
}>`
  position: absolute;
  top: 62rem;
  left: 0;
  width: 100%;
  z-index: -1;

  ${mediaTablet(css`
    top: -20rem;
  `)}

  ${({ $variant }) =>
    $variant === CircuitVariant.SECONDARY &&
    css`
      width: 292rem;
      height: 920rem;
      top: 250rem;
      right: 0;
      left: auto;

      ${mediaTablet(css`
        width: 251rem;
        height: 2441rem;
        top: 750rem;
        left: 0;
        right: auto;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.ABOUT_HISTORY &&
    css`
      top: 0;
      height: 100%;

      ${mediaTablet(css`
        top: 0;
      `)}
    `};
`;

export const Circuit2WindowBg = styled.img`
  position: absolute;
  pointer-events: none;
  user-select: none;
  top: 3vw;
  left: 8.5vw;
  width: 83vw;

  ${mediaTablet(css`
    width: 1767rem;
    height: 970rem;
    top: 68rem;
    right: 634rem;
    left: auto;
  `)};
`;

export const Content = styled.div<{ $variant: CircuitProps['variant'] }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 634rem;
  height: 740rem;
  pointer-events: all;

  ${mediaTablet(css`
    margin: 0;
    left: 636rem;
    top: 60rem;
    width: 1750rem;
    height: 815rem;
  `)}

  ${({ $variant }) =>
    $variant === CircuitVariant.SECONDARY &&
    css`
      top: 35rem;
      height: 875rem;

      ${mediaTablet(css`
        margin: 0 auto;
        top: 70rem;
        left: 0;
        width: 1750rem;
        height: 955rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.WARNING &&
    css`
      top: 825rem;
      height: 930rem;

      ${mediaTablet(css`
        margin: 0 auto;
        top: 800rem;
        left: 0;
        width: 1450rem;
        height: 890rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.WIRES &&
    css`
      top: 340rem;
      height: 950rem;

      ${mediaTablet(css`
        margin: 0 auto;
        top: 355rem;
        left: 0;
        width: 1420rem;
        height: 890rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.ERROR &&
    css`
      top: 220rem;
      height: 930rem;

      ${mediaTablet(css`
        margin: 0 auto;
        top: 225rem;
        left: 0;
        width: 1470rem;
        height: 930rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.ABOUT_HISTORY &&
    css`
      top: 263rem;
      height: 920rem;

      ${mediaTablet(css`
        margin: 0 auto;
        top: 270rem;
        left: 0;
        width: 1600rem;
        height: 1125rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.FOOTER &&
    css`
      top: 115rem;
      height: 720rem;

      ${mediaTablet(css`
        margin: 0 auto;
        top: 410rem;
        left: 0;
        width: 2050rem;
        height: 770rem;
      `)}
    `};
`;

export const CloseButton = styled.div<{ $variant: CircuitProps['variant'] }>`
  position: absolute;
  pointer-events: all;

  ${({ $variant }) =>
    $variant === CircuitVariant.WARNING &&
    css`
      top: 626rem;
      right: 44rem;

      ${mediaTablet(css`
        top: 630rem;
        right: 358rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.WIRES &&
    css`
      top: 145rem;
      right: 44rem;

      ${mediaTablet(css`
        top: 166rem;
        right: 358rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === CircuitVariant.ERROR &&
    css`
      top: 32rem;
      right: 44rem;

      ${mediaTablet(css`
        top: 46rem;
        right: 358rem;
      `)}
    `};
`;
