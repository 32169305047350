import { useRouter } from 'next/router';
import { FC } from 'react';

import Button from 'components/Button/Button';
import Circuit from 'components/Circuit/Circuit';
import FlashingLight from 'components/FlashingLight/FlashingLight';
import Layout from 'components/Layout/Layout';
import { LayoutBackgroundVariant } from 'components/Layout/LayoutContainer/LayoutContainer';
import NewCircuit from 'components/NewCircuit/NewCircuit';
import { useCopyStore, useGlobalStore, useUserStore } from 'store';
import GTM, { GTM_CATEGORY } from 'u9/utils/gtm';
import assets, { NewCircuitVariant } from 'utils/assets';
import { ROUTES } from 'utils/routes';

import LandingCapsulesCircuit from './LandingCapsulesCircuit/LandingCapsulesCircuit';
import LandingLab from './LandingLab/LandingLab';
import useMotion from './LandingSeason1.motion';

import * as Styled from './LandingSeason1.styles';

const LAB_ID = 'lab';

export interface LandingSeason1Props {}

const LandingSeason1: FC<LandingSeason1Props> = () => {
  const { isDesktopView } = useGlobalStore();
  const { copy, socials } = useCopyStore(({ copy }) => ({
    copy: copy.index.content.season1,
    socials: copy.global.socials,
  }));
  const socialsCopy = [socials.twitter, socials.discord];
  const { landingContentRef, landingLabRef, zoom } = useMotion();
  const router = useRouter();
  const { isLoggedIn } = useUserStore();

  const zoomToLab = (
    elementToZoom: {
      width: number;
      height: number;
      top: number;
      left: number;
    },
    nextRoute: string
  ) => {
    zoom(elementToZoom, () => {
      router.push(nextRoute);
    });
  };

  return (
    <div ref={landingContentRef}>
      <Layout.Container background={LayoutBackgroundVariant.S1_GRADIENT}>
        <Styled.Wrapper>
          <Styled.SecondaryWrapper>
            <NewCircuit variant={NewCircuitVariant.STATIC_LANDING_SECONDARY}>
              <Styled.SecondaryContentWrapper>
                <Circuit.Content
                  {...copy.circuit3}
                  extraContent={
                    <Button
                      label={copy.circuit3.cta}
                      href={ROUTES.FAQ}
                      paddingSize="sm"
                      onClick={() => {
                        GTM.trackEvent(
                          GTM_CATEGORY.Homepage,
                          'Click',
                          'Go to FAQ'
                        );
                      }}
                    />
                  }
                  bodySize="sm"
                  bodyUpperCase={false}
                />
              </Styled.SecondaryContentWrapper>
              <Styled.FlashingLightsSecondary>
                {[...Array(3)].map((_, index) => (
                  <FlashingLight key={index} />
                ))}
              </Styled.FlashingLightsSecondary>
            </NewCircuit>
          </Styled.SecondaryWrapper>

          <LandingCapsulesCircuit />

          <Styled.PrimaryWrapper>
            <NewCircuit variant={NewCircuitVariant.LANDING_PRIMARY}>
              <Circuit.Content
                {...copy.circuit2}
                extraContent={
                  <Button
                    label={copy.circuit2.cta}
                    href={ROUTES.ABOUT}
                    onClick={() => {
                      GTM.trackEvent(
                        GTM_CATEGORY.Homepage,
                        'Click',
                        'About metaverse'
                      );
                    }}
                    paddingSize="sm"
                  />
                }
              />
              <Styled.FlashingLightsPrimary>
                {[...Array(6)].map((_, index) => (
                  <FlashingLight key={index} />
                ))}
              </Styled.FlashingLightsPrimary>
            </NewCircuit>
          </Styled.PrimaryWrapper>
          <Styled.FooterWrapper>
            <NewCircuit variant={NewCircuitVariant.LANDING_FOOTER}>
              <Circuit.Content
                titleDecoration
                extraContent={
                  <Styled.LinksList>
                    {socialsCopy
                      .filter(({ icon }) => icon === 'discord')
                      .map(({ href, title, label }) => (
                        <li key={title}>
                          <Button
                            title={title}
                            icon="discord"
                            href={href}
                            onClick={() => {
                              GTM.trackEvent(
                                GTM_CATEGORY.Homepage,
                                'Click',
                                label
                              );
                            }}
                            label={label}
                            uppercase
                            paddingSize="sm"
                          />
                        </li>
                      ))}
                  </Styled.LinksList>
                }
                {...copy.footer}
              />
            </NewCircuit>
          </Styled.FooterWrapper>
          {isDesktopView && (
            <Styled.ElevatorsGround
              src={assets.svgs.landingPage.elevatorsGround}
            />
          )}
          <Styled.LandingLabWrapper ref={landingLabRef} id={LAB_ID}>
            <LandingLab onClickLab={zoomToLab} isLoggedIn={isLoggedIn} />
          </Styled.LandingLabWrapper>
        </Styled.Wrapper>
      </Layout.Container>
    </div>
  );
};

export default LandingSeason1;
