import Image from 'next/image';
import styled, { css } from 'styled-components';

import assets from 'utils/assets';
import {
  aspectRatio,
  backgroundImage,
  setTypography,
} from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.section`
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: rgb(2, 0, 67);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 67, 1) 0%,
    rgba(1, 15, 146, 1) 80%
  );
  z-index: -1;

  ${mediaTablet(css`
    margin-top: ${({ theme }) => -theme.layout.header.height}rem;
  `)}
`;

const backgroundSize = css`
  width: 100%;
  height: 1680rem;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  display: block;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
`;

export const WelcomeBackgroundSeason1 = styled.div`
  position: relative;
  height: 1570rem;

  ${mediaTablet(css`
    height: 1850rem;
  `)}
`;

export const WelcomeBackgroundLightsOffImage = styled(Image)<{
  $shown: boolean;
}>`
  ${aspectRatio(5870, 1839)};
  object-fit: cover;
  height: 1839rem !important;
  width: 100% !important;
  min-width: initial !important;
  max-width 5870rem !important;
  position: absolute;
  z-index: -1;
  opacity: ${({ $shown }) => ($shown ? 1 : 0)};
`;

export const WelcomeBackgroundLightsOnImage = styled(Image)<{
  $shown: boolean;
}>`
  ${aspectRatio(5870, 1839)};

  object-fit: cover;
  height: 1839rem !important;
  width: 100% !important;
  min-width: initial !important;
  max-width 5870rem !important;
  position: absolute;
  z-index: -1;
  opacity: ${({ $shown }) => ($shown ? 1 : 0)};
`;

export const WelcomeBackgroundMobile = styled(Image)`
  ${backgroundSize};
`;

export const Content = styled.div`
  position: absolute;
  top: 250rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaTablet(css`
    top: 200rem;
  `)};
`;

export const TopContent = styled.div<{ order?: number }>`
  margin: -15% 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ order }) => order && `order: ${order};`}

  ${mediaTablet(css`
    margin: 0 auto;
  `)};
`;

export const Headline = styled.h3`
  ${({ theme: { locale } }) => setTypography('heading5', locale)};
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  margin-bottom: 30rem;

  ${mediaTablet(css`
    margin-bottom: 32rem;
  `)};
`;

export const Logo = styled.div`
  width: min(694rem, 90vw);
  object-fit: contain;
  margin-bottom: 58rem;

  ${mediaTablet(css`
    width: 1030rem;
    height: 444rem;
    margin-bottom: 48rem;
  `)};
`;

export const Body = styled.p`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
  margin: -44rem 0 64rem;

  span {
    display: block;
  }

  ${mediaTablet(css`
    margin: -72rem 0 64rem;
  `)};
`;

export const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  li:not(:last-child) {
    margin-bottom: 20rem;
  }

  ${mediaTablet(css`
    flex-direction: row;
    li:not(:last-child) {
      margin-right: 40rem;
      margin-bottom: 0;
    }
  `)}
`;

export const Popup = styled.div<{ $withButtons: boolean }>`
  order: 1;
  position: relative;
  top: 0;
  right: auto;
  height: ${({ $withButtons }) => ($withButtons ? 'auto' : '100%')};
  width: 90%;
  max-height: ${({ $withButtons }) => ($withButtons ? 'none' : '75%')};
  border: 4px solid ${({ theme }) => theme.colors.black};
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.black};
  overflow: hidden;

  ${({ theme: { locale }, $withButtons }) =>
    $withButtons &&
    `
    position: absolute;
    top: 17vh;

    & > div {
      aspect-ratio: auto;
    }

    p {
      ${setTypography('popupBody', locale)};
    }
  `};

  ${({ $withButtons }) =>
    $withButtons &&
    mediaTablet(css`
      top: 0vh;
    `)};

  ${mediaTablet(css`
    right: 5%;
    width: auto;
    max-width: 27.5%;
    position: absolute;
  `)};
`;

export const PopupBorder = styled.div`
  border: 2px solid #0f2e7e;
  border-radius: 30px;
  ${backgroundImage(assets.svgs.landingPage.popupBackground, [281, 155])}
  background-size: cover;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  top: 7.5%;
  margin: 0 5%;
  background: linear-gradient(
    180deg,
    #ffffff -22.51%,
    rgba(122, 183, 209, 0) 77.49%
  );
  opacity: 0.5;
  height: 1em;
  border-radius: 30px;
`;

export const PopupInner = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.teal};
  border-right-color: #687caf;
  border-top-color: #687caf;
  height: fit-content;
  cursor: pointer;
  border-radius: 30px;
  height: 100%;
  padding: 1.5em;
  white-space: pre-line;
  text-align: center;

  h5 {
    color: ${({ theme }) => theme.colors.turbo};
    ${({ theme: { locale } }) => setTypography('heading5Tooltip', locale)};
    white-space: pre-wrap;
    margin-bottom: 10px;

    span {
      text-transform: none;
    }
  }

  p {
    text-transform: none;
    white-space: pre-wrap;
  }
`;

export const PopupLinksList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40rem;
  margin: 20rem 0 0;

  a {
    padding: 24rem;
    ${({ theme: { locale } }) => setTypography('body4', locale)};
    line-height: 0.7;
  }
`;
